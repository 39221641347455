import React, { useState } from 'react'
import styled from 'styled-components'
import useStorage from '../hooks/use-storage'
import useTimeout from '../hooks/use-timeout'
import NewsletterEmailInput from './newsletterEmailInput'

const MAX_TRIES = 3
const POPUP_DELAY_MS = 6000
const CLOSE_POPUP_SUCCESS_DELAY = 1000

const NewsletterWrapper = styled.div`
  backdrop-filter: blur(10px);
  overflow: hidden;
  .content {
    line-height: 120%;
    color: var(--white);
  }
  input::placeholder {
    word-spacing: 6px;
    font-weight: 300;
  }
`

const BlackDiv = styled.div`
  background-color: var(--black);
  opacity: 0.5;
  height: 100%;
  width: 100%;
  position: absolute;
`

const BlurredDiv = styled.div`
  background-color: var(--white);
  position: absolute;
  filter: blur(5px);
  height: 300px;
  width: 475px;
  border-radius: 10px;
  opacity: 0.3;

  @media screen and (max-width: 1023px) {
    width: calc(100% - 40px);
  }
`

const Container = styled.div`
  height: 300px;
  width: 475px;

  @media screen and (max-width: 1023px) {
    width: calc(100% - 40px);
  }
`

const NewsletterPopup = ({ manuallyOpenNewsletter, setManuallyOpenNewsletter }) => {
  const [open, setOpen] = useState(manuallyOpenNewsletter)
  const [newsletterCanceledCount, setNewsletterCanceledCount] = useStorage({
    key: 'newsletterCanceledCount',
    initialValue: 0,
    type: 'localStorage',
  })
  const [hasBeenShownInSession, setHasBeenShownInSession] = useStorage({
    key: 'newsletterPopupShown',
    initialValue: false,
    type: 'sessionStorage',
  })

  const openIfAllowed = () => {
    if (newsletterCanceledCount >= MAX_TRIES || hasBeenShownInSession) return
    setHasBeenShownInSession(true)
    setOpen(true)
  }

  const closePopup = ({ customCount }) => {
    setNewsletterCanceledCount(customCount || newsletterCanceledCount + 1)
    setOpen(false)
    setManuallyOpenNewsletter(false)
  }

  useTimeout(openIfAllowed, POPUP_DELAY_MS)

  if (open || manuallyOpenNewsletter) {
    return (
      <NewsletterWrapper className="h-screen w-full z-50 fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center">
        <BlackDiv />
        <BlurredDiv />
        <Container className="content p-10 text-xl flex justify-center items-center flex-col z-10 relative">
          <div onClick={closePopup} className="flex cursor-pointer absolute top-5 right-5">
            <p className="pl-10">x</p>
          </div>
          <div className="flex flex-col justify-center items-center mb-8">
            <div className="w-full h-full">
              <p className="uppercase text-2xl text-center" style={{ fontSize: '24px' }}>
                Stay On Us
              </p>
              <p className="text-center text-xl" style={{ fontSize: '18px' }}>
                One subscriber gets a free night each month
              </p>
            </div>
          </div>
          <div className="">
            <NewsletterEmailInput
              onSuccess={() =>
                setTimeout(
                  () => closePopup({ customCount: MAX_TRIES + 1 }),
                  CLOSE_POPUP_SUCCESS_DELAY
                )
              }
            />
          </div>
        </Container>
      </NewsletterWrapper>
    )
  }
  return null
}

export default NewsletterPopup
