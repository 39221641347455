import { useLocation } from '@reach/router'
import React from 'react'
import MediaQuery from 'react-responsive'
import styled from 'styled-components'
import useNavigation from '../hooks/use-navigation'
import GlobalStyles from '../styles/globalStyles'
import Typography from '../styles/typography'
import Footer from './footer'
import Header from './header'
import MainContent from './mainContent'
import NewsletterPopup from './newsletterPopup'
// import useNavigation from '../hooks/use-navigation'
// import { navigate } from 'gatsby-link'

const LayoutStyles = styled.div`
  // For the Way experiences tab
  #kouto-app-root > div > div > div:not(:first-child) {
    padding: 20px;
  }
`

const Layout = ({ children, scroll, uri }) => {
  const location = useLocation()
  const navigation = useNavigation()
  const [manuallyOpenNewsletter, setManuallyOpenNewsletter] = React.useState(false)
  console.log('🚀 ~ manuallyOpenNewsletter:', manuallyOpenNewsletter)

  React.useEffect(() => {
    console.log('manuallyOpenNewsletter', manuallyOpenNewsletter)
  }, [manuallyOpenNewsletter])

  const isBooking =
    new RegExp('^/booking(/.*)?$').test(location.pathname) || location.pathname === '/'

  return (
    <>
      <GlobalStyles />
      <Typography />
      <NewsletterPopup
        manuallyOpenNewsletter={manuallyOpenNewsletter}
        setManuallyOpenNewsletter={setManuallyOpenNewsletter}
      />
      <LayoutStyles
        className={`h-auto lg:h-screen w-full flex flex-col justify-between lg:overflow-scroll${
          scroll ? '' : ' lg:overflow-hidden'
        }`}
      >
        <MediaQuery maxWidth={1023}>
          {matches =>
            matches ? (
              <div className='relative'>
                <Header
                  setManuallyOpenNewsletter={setManuallyOpenNewsletter}
                  isBooking={isBooking}
                />
                <MainContent>{children}</MainContent>
              </div>
            ) : (
              <>
                <Header
                  setManuallyOpenNewsletter={setManuallyOpenNewsletter}
                  isBooking={isBooking}
                />
                <MainContent>{children}</MainContent>
              </>
            )
          }
        </MediaQuery>
        <Footer />
        <script src="https://widget.siteminder.com/ibe.min.js"></script>
      </LayoutStyles>
    </>
  )
}

export default Layout
