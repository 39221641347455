import { useMatch } from '@reach/router'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import useFooter from '../hooks/use-footer'
import FooterEmailInput from './footerEmailInput'
import AddressAndCopyright from './addressAndCopyright'

const FooterStyles = styled.footer`
  color: var(--black);
  z-index: 10;

  li {
    font-size: 0.75rem;
  }
  form {
    display: flex;
    flex-direction: row;

  }
  input::placeholder {
    color: var(--black);
  }
  button {
    font-size: 1.125rem;
  }
  .is-booking {
    position: fixed;
    bottom: 0;
    li {
    }
    form {
    }
    input::placeholder {
      color: var(--white);
    }
  }

  @media screen and (max-width: 1023px) {
    .booking-wrapper {
      background-color: var(--black);
      position: relative;
      flex-direction: column;
      color: var(--white);
      
      
    }
    li {
      border: none;
      font-size: 14px
      font-family: var(--favoritMono);
      font-weight: 300;
    }
  }

  @media screen and (min-width: 1024px) {
   li {
        border-bottom: 1px dashed var(--black);
      }
      form {
        border-bottom: 1px dashed var(--black);
      }
    .is-booking {
      color: var(--white);
      li {
        border-bottom: 1px dashed var(--white);
      }
      form {
        border-bottom: 1px dashed var(--white);
      }
      input::placeholder {
        color: var(--white);
      }
    }
  }

  .info-block {
    background-color: var(--blue);
    color: var(--white);
    li {
      border-bottom: none;
      &:first-of-type {
        max-width: 130px;
        word-break: break-all;
      }
      &:nth-of-type(2) {
        text-align: right;
        padding-right: 2rem;
      }
      &:nth-of-type(3) {
        text-align: center;
      }
    }
    .address {
      max-width: 300px;
    }
  }
`

const Footer = () => {
  const footer = useFooter()

  const match = useMatch('/booking')

  return (
    <FooterStyles>
      <div
        className={`booking-wrapper relative bottom-auto w-full flex flex-row items-end justify-between p-4 lg:p-4 ${
          match ? 'is-booking' : ''
        }`}
      >
        <div className="mono text-xs uppercase flex flex-col lg:flex-row items-end w-full lg:w-auto">
          {/* email input */}
          <div className="hidden lg:block">
            <FooterEmailInput isBooking={match} />
          </div>
          {/* end email input */}

          {/* footer links */}
          <ul className="w-full flex flex-col lg:flex-row align-center justify-between py-4 lg:p-0 gap-10">
            {footer.footerItems.map(item => (
              <li key={item._key} className="md:ml-5 mono">
                {item.external ? (
                  <a href={item.link} target="_blank" rel="noopener noreferrer" className="">
                    {item.text}
                  </a>
                ) : (
                  <Link to={`/${item.link}`} className="">
                    {item.text}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          {/* end footer links */}
        </div>

        {/* footer description */}
        <div className="hidden lg:block">
          <p className="serif text-xl">{footer.text}</p>
        </div>
        {/* end footer description */}
        <AddressAndCopyright />
      </div>
    </FooterStyles>
  )
}

export default Footer
