import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --grey: #A7A7A7;
    --activeGrey: #D9D9D9;
    --white: #FFFFFF;
    --blue: #001488;

    --favoritMono: 'FavoritMono', monospace;
    --sabon: 'linotype-sabon', serif;
    --agipoReg: 'Agipo Regular', sans-serif;
    --agipoItalic: 'Agipo Regular Italic', sans-serif;
  }

  * {
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  html,
  body {
    font-family: var(--agipoReg);
    
  }

  @media screen and (min-width: 1024px) {
    html, body {
    overscroll-behavior-y: none;
    }
  }

  .mono {
    font-family: var(--favoritMono);
  }

  .serif {
    font-family: var(--sabon);
    font-weight: 400;
    letter-spacing: 0.01em;
  }

  .sans-reg {
    font-family: var(--agipoReg);
    letter-spacing: 0.01em;
  }

  .sans-italic {
    font-family: var(--agipoItalic);
  }

`

export default GlobalStyles
