import astrochimp from 'astrochimp'
import React, { useState } from 'react'
import useNewsletter from '../hooks/use-newsletter'
import styled from 'styled-components'

const EmailWrapper = styled.div`
  display: flex;
  justify-content: center;
  form {
    display: flex;
    flex-direction: row;
    border-bottom: 1px dashed var(--white);
    background-color: var(--white);
    padding: 12px;
    width: 350px;
  }
  @media screen and (max-width: 1023px) {
    form {
      width: calc(100% - 40px);
    }

    input {
      width: calc(100% - 40px);
    }
  }
  input {
    font-size: 14px;
    width: 300px;
  }
  input::placeholder {
    color: var(--black);
    text-align: center;
    font-size: 14px;
  }

  button {
    font-size: 1.5rem;
    padding-right: 7px;
  }
  color: var(--black);
`
const NewsletterEmailInput = ({ onSuccess }) => {
  const newsletter = useNewsletter()

  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    const url = newsletter.list
    const data = { EMAIL: email }

    astrochimp(url, data, err => {
      if (err) {
        console.log(err)
        if (err.msg.indexOf('already subscribed') >= 0) {
          setStatus('*Already Subscribed')
          if (onSuccess) onSuccess()
        } else {
          setStatus('*Invalid Address')
        }
      } else {
        setEmail('Thank you!')
        setStatus('')
        if (onSuccess) onSuccess()
      }
    })
  }

  const handleInputChange = e => {
    setEmail(e.target.value)
  }
  return (
    <EmailWrapper>
      <span>{status}</span>
      <form className=" mono" onSubmit={handleSubmit}>
        <input
          type="email"
          required={true}
          value={email}
          onChange={handleInputChange}
          placeholder="your e-mail"
          className="uppercase focus:outline-none bg-transparent mono "
        />
        <button className="focus:outline-none inline" type="submit" style={{ marginBottom: -4 }}>
          &#8594;
        </button>
      </form>
    </EmailWrapper>
  )
}

export default NewsletterEmailInput
