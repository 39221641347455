import { DateTime } from 'luxon'
import React from 'react'
import styled from 'styled-components'

const AddressAndCopyrightStyles = styled.div`
  display: none;
  @media screen and (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 24px;

    .address {
      font-size: 0.6rem;
      text-transform: uppercase;
      line-height: 1.2;
    }
  }
`

const AddressAndCopyright = ({ children }) => {
  return (
    <AddressAndCopyrightStyles>
      <div className="mono address">
        <p className="">333 Mossy Hill Road</p>
        <p className="">Catskill, NY</p>
      </div>
      <p className="text-lg">
        ® {DateTime.now().toLocaleString({ year: 'numeric' })} PIAULE CATSKILL
      </p>
    </AddressAndCopyrightStyles>
  )
}

export default AddressAndCopyright
