import { Link } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import Logo from '../assets/images/logo.inline.svg'
import useNavigation from '../hooks/use-navigation'
import HamburgerIcon from './hamburgerIcon'
import ReserveButton from './reserveButton'

const HeaderStyles = styled.header`
  height: ${props => (props.isBooking ? '98px' : `69px`)};
  nav {
    border-color: var(--grey);
  }

  a {
    color: var(--grey);
  }

  .booking-active {
    background-color: transparent;
    color: var(--black);

    nav {
      border-color: var(--white);
    }

    .side-logo--booking svg {
      fill: var(--white);
    }
  }

  .nav__link {
    color: var(--black);
    border-top: 1px solid transparent;
    font-size: 24px;
  }

  .active {
    color: var(--activeGrey);
    border: none;
  }

  .side-logo svg {
    max-width: 100%;
  }

  .mobile-nav-wrapper {
    color: var(--black);
  }

  @media screen and (max-width: 1023px) {
    height: 50px;
  }

  @media screen and (min-width: 1024px) {
    .booking-active {
      color: var(--white);
      a {
        color: var(--white);
      }
    }

    .active {
      border-top: none;
    }

    .side-logo:not(.side-logo--booking) svg {
      width: 220px;
    }

    nav ul {
      margin-top: -0.5rem;
    }

    .booking-active nav ul {
      margin-top: calc(-0.5rem - 1px);
    }
  }
`

const Header = ({ isBooking, setManuallyOpenNewsletter }) => {
  const navigation = useNavigation()

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <HeaderStyles className="sticky top-0 z-20" isBooking={isBooking}>
      <div className={`w-full relative`}>
        <div
          className={`lg:w-full lg:flex lg:flex-row lg:justify-between ${
            isBooking ? 'booking-active lg:items-start' : 'lg:items-center bg-white'
          } lg:p-4`}
        >
          <div className={`hidden lg:block p-3 lg:p-0 lg:w-1/3`}>
            <Link className={`side-logo ${isBooking ? 'side-logo--booking' : ''}`} to="/">
              <Logo />
            </Link>
          </div>
          <div
            className={`flex justify-center items-center gap-2 lg:hidden p-3 lg:p-0 mobile-nav-wrapper`}
          >
            <HamburgerIcon
              setManuallyOpenNewsletter={setManuallyOpenNewsletter}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              isBooking={isBooking}
            />
            <Link
              className={`side-logo w-full ml-2 z-10 ${
                isBooking && !menuOpen ? 'side-logo--booking' : ''
              } `}
              to="/"
            >
              <Logo />
            </Link>
            <ReserveButton isBooking={isBooking} menuOpen={menuOpen} />
          </div>
          <nav className="hidden lg:flex align-center justify-center border-t border-b lg:border-t-0 lg:border-b-0 relative left-0 w-auto">
            <ul className="flex flex-row justify-between lg:justify-center items-center overflow-scroll sans-reg w-full">
              {navigation.navItems.map(item => (
                <li key={item._key} className="flex flex-row items-center">
                  <Link
                    activeClassName={'active'}
                    className="nav__link uppercase px-3 sans-reg"
                    to={`/${item.slug}`}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>

            <ReserveButton isBooking={isBooking} menuOpen={menuOpen} />
          </nav>
        </div>
      </div>
    </HeaderStyles>
  )
}

export default Header
