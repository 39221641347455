import addToMailchimp from 'gatsby-plugin-mailchimp'
import React, { useState } from 'react'
import styled from 'styled-components'

const EmailWrapper = styled.div`
  form {
    display: flex;
    flex-direction: row;
    border-bottom: ${props =>
      props.isBooking ? '1px dashed var(--white)' : '1px dashed var(--black)'};
    color: ${props => (props.isBooking ? 'var(--white)' : 'var(--black)')};
  }
  input::placeholder {
    color: ${props => (props.isBooking ? 'var(--white)' : 'var(--black)')};
  }
  button {
    font-size: 1.125rem;
  }
  color: var(--white);
`
const FooterEmailInput = ({ onSuccess, isBooking }) => {
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')

  const handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log('🚀 ~ data:', data)
        if (data.result === 'success') {
          setEmail('Thank you!')
          setStatus('')
          if (onSuccess) onSuccess()
        }
        if (data.result === 'error' || data.result === 'failed') {
          if (data.msg.indexOf('already subscribed') >= 0) {
            setStatus('*Already Subscribed')
          } else {
            setStatus('*Invalid Address')
          }
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  const handleInputChange = e => {
    setEmail(e.target.value)
  }

  return (
    <EmailWrapper isBooking={isBooking}>
      <span>{status}</span>
      <form className="inline" onSubmit={handleSubmit}>
        <input
          type="email"
          required={true}
          value={email}
          onChange={handleInputChange}
          placeholder="your e-mail"
          className="uppercase focus:outline-none bg-transparent sans-reg "
        />
        <button className="focus:outline-none" type="submit">
          &#8594;
        </button>
      </form>
    </EmailWrapper>
  )
}

export default FooterEmailInput
