import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ReserveButtonStyles = styled.div`
  z-index: 10;
  width: 100px;
  a {
    width: 100px;
    color: ${props => (props.isBooking ? 'var(--white)' : 'var(--black)')};
    border: 1px solid ${props => (props.isBooking ? 'var(--white)' : 'var(--black)')};
    transition: color 0.4s ease, background-color 0.4s ease;

    overflow: hidden;

    &:hover {
      background-color: ${props => (props.isBooking ? 'var(--white)' : 'var(--black)')};
      color: ${props => (props.isBooking ? 'var(--black)' : 'var(--white)')};
    }

    span {
      animation: ${props =>
        props.isBooking || props.menuOpen ? 'marquee 10s linear infinite' : 'none'};
      display: inline-block;
      width: max-content;
    }

    @keyframes marquee {
      0% {
        transform: translate(90%);
      }
      100% {
        transform: translate(-100%);
      }
    }
  }
  @media screen and (max-width: 1023px) {
    a {
      color: ${props =>
        props.isBooking && !props.menuOpen ? 'var(--white)' : 'var(--black)'} !important;
      border: 1px solid
        ${props => (props.isBooking && !props.menuOpen ? 'var(--white)' : 'var(--black)')};
      width: 54px;
      height: 21px;
    }
    width: 54px;
    height: 21px;
    font-size: 0.75rem;
  }
`

const ReserveButton = ({ isBooking, menuOpen }) => {
  const [date, setDate] = useState(new Date())
  const [temp, setTemp] = useState('')
  const [cond, setCond] = useState('')
  const zip = 12414
  const apiKey = '57f23fe5225cf6f01b7d940b9222d286'

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    let timer = setInterval(() => setDate(new Date()), 1000)

    const getWeather = async () => {
      await fetch(
        `https://api.openweathermap.org/data/2.5/weather?zip=${zip},us&units=imperial&appid=${apiKey}`,
        { signal }
      )
        .then(res => res.json())
        .then(data => {
          setTemp(data.main.temp.toFixed(0))
          setCond(data.weather[0].description)
        })
        .catch(err => {
          throw new Error(err)
        })
    }

    getWeather()

    return function cleanup() {
      clearInterval(timer)
      controller.abort()
    }
  }, [])

  return (
    <ReserveButtonStyles isBooking={isBooking} menuOpen={menuOpen}>
      <a
        href={'https://direct-book.com/piaule-catskill/properties/piaulecatskilldirect'}
        className={`text-l uppercase py-0.5 ${
          isBooking || menuOpen ? 'px-2' : ''
        }  border inline-block text-center box-border ${
          isBooking ? 'cursor-default' : 'cursor-pointer'
        }`}
      >
        <span suppressHydrationWarning>
          {isBooking || menuOpen ? `${temp}˚F ${cond} ${format(date, 'HH:mm:ss')}` : 'Reserve'}
        </span>
      </a>
    </ReserveButtonStyles>
  )
}

export default ReserveButton
