import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import useNavigation from '../hooks/use-navigation'

const DivStyles = styled.div`
  /* Icon 1 */

  .hamburger-booking#nav-icon4 span {
    background: var(--white);
  }

  #nav-icon1,
  #nav-icon2,
  #nav-icon3,
  #nav-icon4 {
    width: 40px;
    height: 18px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon1 span,
  #nav-icon3 span,
  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--black);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  /* Icon 4 */

  #nav-icon4 {
  }

  #nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(2) {
    top: 8px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(3) {
    top: 16px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4.open span {
    background: var(--black);
  }

  #nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(26deg);
    -moz-transform: rotate(26deg);
    -o-transform: rotate(26deg);
    transform: rotate(26deg);
    top: 0px;
    left: 0px;
  }

  #nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-26deg);
    -moz-transform: rotate(-26deg);
    -o-transform: rotate(-26deg);
    transform: rotate(-26deg);
    top: 18px;
    left: 0px;
  }

  .nav__link {
    color: var(--black);
    font-size: 36px !important;
  }

  .subscribe-button {
    border: 1px solid var(--black);
    text-transform: uppercase;
    padding: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 0.75rem;
  }
`

const HamburgerIcon = ({ menuOpen, setMenuOpen, isBooking, setManuallyOpenNewsletter }) => {
  const navigation = useNavigation()

  return (
    <>
      <DivStyles isBooking={isBooking} className="">
        {menuOpen && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-white flex flex-col justify-between ">
            <div className="flex flex-col gap-3 mt-32">
              {navigation.navItems.map(item => (
                <li key={item._key} className="flex flex-row items-center ">
                  <Link
                    activeClassName={'active'}
                    className="nav__link uppercase px-3"
                    to={`/${item.slug}`}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </div>
            <div>
              <div className="flex flex-col gap-6">
                <li className="flex flex-row items-center justify-end ">
                  <a href={'https://shop.piaule.com'} className="nav__link text-4xl uppercase px-3">
                    Shop
                  </a>
                </li>
                <li className="flex flex-row items-center justify-end ">
                  <a
                    href={'https://direct-book.com/piaule-catskill/properties/piaulecatskilldirect'}
                    className="nav__link text-4xl uppercase px-3"
                  >
                    Book Now
                  </a>
                </li>
              </div>
            </div>
            <button
              onClick={() => setManuallyOpenNewsletter(true)}
              className="subscribe-button mono"
            >
              Subscribe for updates and offers
            </button>
          </div>
        )}
        <div
          id="nav-icon4"
          className={`${menuOpen ? 'open' : ''} ${isBooking ? 'hamburger-booking' : ''}`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </DivStyles>
    </>
  )
}

export default HamburgerIcon
