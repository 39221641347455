import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const MainStyles = styled.main`
  @media screen and (max-width: 1023px) {
    min-height: calc(100vh - 50px);
  }
`

const Layout = ({ children }) => {
  const mainEl = useRef(null)

  const handleWheel = e => {
    const { deltaY } = e
    const { current } = mainEl

    if (current.scrollWidth === window.innerWidth) return

    current.scrollLeft += deltaY * 1.5

    /* if (
          current.scrollWidth - current.scrollLeft === window.innerWidth
          && index !== -1
          && index !== navigation.navItems.length - 1
        ) {
          window.removeEventListener('wheel', handleWheel);
    
          const { slug } = navigation.navItems[index + 1];
    
          navigate(`/${slug}`);
        } */
  }

  useEffect(() => {
    window.addEventListener('wheel', handleWheel, { passive: false })

    return function cleanup() {
      window.removeEventListener('wheel', handleWheel)
    }
  })

  return (
    <MainStyles ref={mainEl} className="h-full w-full lg:overflow-scroll">
      {children}
    </MainStyles>
  )
}

export default Layout
